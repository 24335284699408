<template>
  <v-container>
    <v-alert-success :show="isSuccess" :close="closeAlert" :text="successText"></v-alert-success>
    <v-alert-error :show="isError" :msg="msgError" :close="closeAlert"></v-alert-error>
    <v-row justify="center">
      <v-dialog v-model="show" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span t-data="page-headline" class="headline">Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-form
                ref="form"
                v-model="validate"
                lazy-validation
            >
              <v-row>
                <v-col cols="12" t-data="info-input">
                  <v-text-field label="* Current Password" type="password" v-model="passwordObject.currentPassword" :rules="current_password_rule" required></v-text-field>
                </v-col>
                <v-col cols="12" t-data="info-input">
                  <v-text-field label="* New Password*" type="password" v-model="passwordObject.newPassword" :rules="new_password_rule" required></v-text-field>
                </v-col>
                <v-col cols="12" t-data="info-input">
                  <v-text-field label="* Confirm Password*" type="password" v-model="passwordObject.confirmPassword" :rules="confirm_password_rule" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" t-data="close-btn" text @click="closePopup">Close</v-btn>
            <v-btn color="blue darken-1" t-data="save-btn" @click="save" text>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    </v-container>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import AlertSuccess from "../../components/AlertSaveUserSuccess"
  import AlertError from '../../components/AlertError'

  export default {
    name: 'ChangePasswordPopup',
    props: {
      show: {type: Boolean},
      close: {type: Function},
    },
    components: {
      'v-alert-success': AlertSuccess,
      'v-alert-error': AlertError,
    },
    computed: {
      ...mapState('myProfile', ['passwordObject']),
    },
    methods: {
      reset() {
        this.$refs.form.reset()
      },
      closePopup() {
        this.close()
        this.reset()
      },
      async save() {
        if (this.$refs.form.validate()) {
          const res = await this.changePassword({
            current_password: this.passwordObject.currentPassword,
            new_password: this.passwordObject.newPassword,
          })
          if (res.status) {
            this.isSuccess = true
            this.close()
          }
          else {
            this.msgError = res.msg
            this.isError = true
          }
        }
        else {
          this.msgError = 'Incomplete information'
          this.isError = true
        }
      },
      closeAlert() {
        this.isSuccess = false
        this.isError = false
        this.reset()
      },
      ...mapActions('myProfile', [
        'changePassword',
      ]),
    },
    data() {
      return {
        validate: true,
        isSuccess: false,
        isError: false,
        successText: 'New password is saved',
        msgError: '',
        current_password_rule: [
          v => !!v || 'This field is required',
        ],
        new_password_rule: [
          v => !!v || 'This field is required',
          v => (v && v.length >= 6) || 'Password must have at least 6 characters.',
        ],
        confirm_password_rule: [
          v => !!v || 'This field is required',
          v => v === this.passwordObject.newPassword || 'New password and confirm password must be the same.'],
      }
    },
  }
</script>

<style scoped>
  .v-form .col,
  .container .col {
    padding-top: 0;
    padding-bottom: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

</style>
