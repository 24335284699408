<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-success :show="isSuccess" :close="closeSuccess" :text="successText"></v-alert-success>
    <v-alert-error :show="isError" :msg="msgError" :close="closeAlert"></v-alert-error>
    <v-change-password-popup :show="showChangePassword" :msg="msgError" :close="closeChangePassword"></v-change-password-popup>
    <v-row>
      <v-col>
        <p t-data="page-headline" class="headline">Account Setting</p>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
            label="* E-mail"
            required
            :rules="EmailRules"
            v-model="emailValue"
            :error-messages="chkEmailErrorMsg"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn class="ma-2" t-data="save-btn" large color="primary" @click="save">Save</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
              label="* Firstname"
              required
              :rules="NameRules"
              @keypress="isAplabet($event)"
              v-model="userObject.first_name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-right" t-data="info-input">
          <v-text-field
              label="* Lastname"
              required
              :rules="NameRules"
              @keypress="isAplabet($event)"
              v-model="userObject.last_name"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="6" class="text-right" t-data="info-input">
        <v-text-field
            label="Password"
            disabled
            placeholder="*********"
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="text-right">
        <v-btn class="ma-2" t-data="change-btn" large color="primary" @click="openChangePassword">Change</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import PopupLoading from '../../components/PopupLoading'
import AlertSuccess from "../../components/AlertProfileSuccess"
import AlertError from '../../components/AlertError'
import ChangePasswordPopup from './ChangePasswordPopup'
import {searchHandler} from '../../helper/helper'

export default {
  components: {
    'v-popup-loading': PopupLoading,
    'v-change-password-popup': ChangePasswordPopup,
    'v-alert-success': AlertSuccess,
    'v-alert-error': AlertError,
  },
  data() {
    return {
      EmailRules: [
        v => !!v || 'E-mail is required',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
      ],
      NameRules: [
        v => !!v || 'Firstname is required',
        v => /^[ก-๛a-zA-Z]+$/.test(v) || 'Invalid characters'
      ],
      chkEmailErrorMsg: [],
      isError: false,
      showDialog: false,
      showChangePassword: false,
      msgError: '',
      isSuccess: false,
      successText: 'Success update information'
    }
  },
  beforeMount() {
    this.resetState()
    this.fetch()
  },
  methods: {
    async save()  {
      if (this.validate() && this.chkEmailErrorMsg.length === 0) {
        let payload = {
          email: this.userObject.email,
          first_name: this.userObject.first_name,
          last_name: this.userObject.last_name,
        }
        await this.update(payload)
        const isUpdated = await this.update(payload)
        if (isUpdated) {
          return this.isSuccess = true
        }
      }
      this.isError = true
      this.msgError = 'Incomplete information'
    },
    closeAlert() {
      this.isError = false
    },
    closeSuccess() {
      this.isSuccess = false
      this.refreshUser()
      this.$router.push('/dashboard')
    },
    openChangePassword() {
      this.showChangePassword = true
    },
    closeChangePassword() {
      this.showChangePassword = false
    },
    validate() {
      if (!this.$refs.form.validate()) {
        this.isError = true
        this.msgError = 'Incomplete information'
      }
      else {
        return true
      }
    },
    async validateEmail(email) {
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        if (email === '') {
          this.chkEmailErrorMsg = []
        }
        else {
          this.chkEmailErrorMsg = await this.chkExistingEmail({email, isEditMode: true, id: this.userObject.id})
            ? []
            : ['E-mail already exists.']
        }
      })
    },
    isAplabet: function (evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if ((charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90) ||
        (charCode >=161 && charCode <= 238) || (charCode >= 3585 && charCode <= 3662)) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    ...mapActions('myProfile', [
      'fetch',
      'resetState',
      'chkExistingEmail',
      'update'
    ]),
    ...mapActions('auth', [
      'refreshUser',
    ])
  },
  computed: {
    emailValue: {
      get: function () {
        this.validateEmail(this.userObject.email)
        return this.userObject.email
      },
      set: async function (email) {
        this.userObject.email = email
      }
    },
    ...mapState('myProfile', [
      'isLoading',
      'userObject'
    ])
  },
}
</script>

<style scoped>
.v-form .col,
.container .col {
  padding-top: 0;
  padding-bottom: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>

